<template>
    <v-card class="ma-4">
        <v-card-title>
            {{ title }}

            <v-divider
                    class="mx-6"
                    inset
                    vertical
            ></v-divider>


            <v-spacer></v-spacer>


            <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
                    color="grey darken-2"
                    fab
                    small
                    text
                    @click="prev"
            >
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <v-btn
                    color="grey darken-2"
                    fab
                    small
                    text
                    @click="next"
            >
                <v-icon small>
                    mdi-chevron-right
                </v-icon>
            </v-btn>
            <v-btn
                    class="ml-4"
                    color="grey darken-2"
                    outlined
                    @click="setToday"
            >
                Today
            </v-btn>
        </v-card-title>

        <v-row class="fill-height">
            <v-col>

                <v-sheet height="600">
                    <v-calendar
                            ref="calendar"
                            v-model="focus"
                            :events="events"
                            :weekdays="weekday"
                            color="red"
                            type="month"
                            @change="updateRange"
                            @click:event="showEvent"
                            @click:date="viewDay"

                    ></v-calendar>
                    <v-menu

                            v-model="selectedOpen"
                            :activator="selectedElement"
                            :close-on-content-click="false"
                            offset-x
                    >
                        <v-card
                                color="white"
                                flat
                                max-width="450px"
                                min-width="350px"
                        >
                            <v-toolbar
                                    :color="selectedEvent.color??'blue-grey'"
                                    dark

                            >
                                <v-btn
                                        dark
                                        icon
                                        @click="selectedOpen=false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title v-html="selectedEvent.name??'Set Available & Price'"></v-toolbar-title>

                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                            :disabled="(!valid || buttonLoading)"
                                            :loading="buttonLoading"
                                            dark
                                            text
                                            @click="validate"
                                    >Save
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>

                            <v-alert
                                    v-if="Object.keys(errors).length>0"
                                    dense
                                    text
                                    type="error"
                            >
                                <h4 class="subtitle">Correct the following errors:</h4>
                                <ul>
                                    <li
                                            v-for="(error, index) in errors"
                                            :key="index"
                                    >{{ error.message || error }}
                                    </li>
                                </ul>
                            </v-alert>
                            <v-card-text>
                                <v-container>

                                    <v-form
                                            ref="form"
                                            v-model="valid"
                                            :disabled="buttonLoading"
                                            lazy-validation
                                    >
                                        <v-row>
                                            <span v-html="selectedEvent.details"></span>

                                            <v-col cols="12">
                                                <v-text-field v-model="price" clearable label="Extra Money for Install in this day"
                                                              min="0" outlined
                                                              type="number"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-checkbox
                                                        v-model="isActive"
                                                        color="red"
                                                        label="Is Available day for Install ?"
                                                ></v-checkbox>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-checkbox
                                                        v-model="isAllDays"
                                                        color="red"
                                                        label="Duplicate with remain days ?"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {urlAvailableInstallations, urlCreateAvailableInstallation, urlUpdateAvailableInstallation,} from "@/config";

export default {
    data: () => ({
        valid: true,
        title: "Install Calendar",
        loading: true,
        availableInstalls: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        isActive: true,
        isAllDays: false,
        price: null,
        id: 0,
        errors: [],

        weekday: [0,1, 2, 3, 4, 5, 6],
        focus: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],

    }),

    created() {
        this.getAvailableInstallationsFromApi();
    },


    mounted() {
        this.$refs.calendar.checkChange()
    },
    methods: {

        getAvailableInstallationsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlAvailableInstallations)
                .then(response => {
                    this.availableInstalls = response.data;
                    this.loading = false;
                    this.updateRange();
                })
                .catch(error => {
                    alert(error);
                });


        },

        viewDay(event) {

            if (!event.past) {


                this.clear();
                const checkAvailableDate = (date) => {
                    return this.availableInstalls.filter(availableInstall => {
                        return availableInstall.startDate == date;
                    });
                };

                let available = checkAvailableDate(event.date);
                if (available.length > 0) {
                    event['id'] = available[0].id;
                    event['price'] = available[0].price;
                    event['isActive'] = available[0].isActive;
                    event['name'] = (available[0].price !== null) ? "Extra Money  = " + available[0].price : "Free Install";
                    event['color'] = (available[0].isActive) ? ((available[0].price !== null) ? 'orange' : 'green') : 'red';

                }
                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.openDialogAvailable(event.nativeEvent, event)))
                } else {
                    this.openDialogAvailable(event.nativeEvent, event);
                }
            }


            event.nativeEvent.stopPropagation();

        },

        setToday() {
            this.focus = ''
        },

        prev() {
            this.$refs.calendar.prev()
        },

        next() {
            this.$refs.calendar.next()
        },

        showEvent({nativeEvent, event}) {

            if (event.start >= new Date().toISOString().slice(0, 10)) {
                this.clear();
                event['date'] = event.start;

                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.openDialogAvailable(nativeEvent, event)))
                } else {
                    this.openDialogAvailable(nativeEvent, event);
                }
            }
            nativeEvent.stopPropagation();
        },

        openDialogAvailable(nativeEvent, event) {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            this.id = event.id;
            this.price = event.price;
            this.isActive = event.isActive ?? true;
            this.focus = event.date;
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
        },

        updateRange() {
            const events = [];
            this.availableInstalls.forEach(available => {

                events.push({
                    id: available.id,
                    name: (available.price !== null) ? "Extra Money  = " + available.price : "Free Install",
                    start: available.startDate,
                    end: available.endDate,
                    color: (available.isActive) ? ((available.price !== null) ? 'orange' : 'green') : 'red',
                    timed: false,
                    price: available.price ?? null,
                    isActive: available.isActive ?? true,
                });

            });
            this.events = events;
        },

        validate() {

            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {

            this.errors = [];

            let data = {
                price: this.price,
                startDate: this.focus,
                endDate: this.focus,
                isActive: this.isActive,
                isAllDays: this.isAllDays,
            };


            if (this.id > 0)
                networks.fetchFromWeb(urlUpdateAvailableInstallation + this.id, 2, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.getAvailableInstallationsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {

                networks.fetchFromWeb(urlCreateAvailableInstallation, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.getAvailableInstallationsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }
        },

        clear() {

            this.price = null;
            this.isActive = true;
            this.isAllDays = false;
            this.selectedOpen = false;

            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },

    },
}
</script>



